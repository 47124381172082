import { useEffect, useState } from 'react';
import { TextureLoader } from 'three';

export function useLogoTextures(logos) {
  const [textures, setTextures] = useState([]);

  useEffect(() => {
    const loader = new TextureLoader();
    const loadedTextures = logos.map((logo) => {
      const texture = loader.load(logo.url);
      texture.center.set(0.5, 0.5); // Set the center for rotation
      texture.flipY = false; // Avoid flipping the texture on Y axis
      return texture;
    });
    setTextures(loadedTextures);
  }, [logos]);

  return textures;
}
