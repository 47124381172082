import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import ModelDetail from './ModelDetail';
import './App.css';
import Menu from './menu';
import { LuBoxes } from "react-icons/lu";
import { IoCardSharp } from "react-icons/io5";
import { IoBag } from "react-icons/io5";

const models = [
  { linkname: 'Sleevedrawerbox', name: 'Sleeve drawer box mockup', image: 'assets/imgs/Box1.png', category: 'Boxes' },
  { linkname: 'Boxwithlid', name: 'Box with lid mockup', image: 'assets/imgs/Box2.png', category: 'Boxes' },
  { linkname: 'Fliptopbox', name: 'Flip top box mockup', image: 'assets/imgs/Box3.png', category: 'Boxes' },
  { linkname: 'Tuckendbox', name: 'Tuck end box mockup', image: 'assets/imgs/Box4.png', category: 'Boxes' },
  { linkname: 'Trianglestandcardmockup', name: 'Triangle Stand card mockup', image: 'assets/imgs/Card1.png', category: 'Cards' },
  { linkname: 'Invitationpostcardbusinesscard', name: 'Invitation post card business card mockup', image: 'assets/imgs/Card2.png', category: 'Cards' },
  { linkname: 'Bag', name: 'Bag mockup', image: 'assets/imgs/Bag.png', category: 'Bags' },
];

const categories = {
  Boxes: [
    { linkname: 'Sleevedrawerbox', name: 'Sleeve drawer box mockup', image: 'assets/imgs/Box1.png' },
    { linkname: 'Boxwithlid', name: 'Box with lid mockup', image: 'assets/imgs/Box2.png' },
    { linkname: 'Fliptopbox', name: 'Flip top box mockup', image: 'assets/imgs/Box3.png' },
    { linkname: 'Tuckendbox', name: 'Tuck end box mockup', image: 'assets/imgs/Box4.png' },
  ],
  Cards: [
    { linkname: 'Trianglestandcardmockup', name: 'Triangle Stand card mockup', image: 'assets/imgs/Card1.png' },
    { linkname: 'Invitationpostcardbusinesscard', name: 'Invitation post card business card mockup', image: 'assets/imgs/Card2.png' },
  ],
  Bags: [
    { linkname: 'Bag', name: 'Bag mockup', image: 'assets/imgs/Bag.png' },
  ],
};
const categoryIcons = {
  Boxes: <LuBoxes />,
  Cards: <IoCardSharp />,
  Bags: <IoBag />,
};
function Home() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedType, setSelectedType] = useState('');

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedType(''); // Reset the type when the category changes
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const filteredModels = models.filter(model => {
    return (
      (selectedCategory === '' || model.category === selectedCategory) &&
      (selectedType === '' || model.name === selectedType)
    );
  });

  return (
    <>
      <Menu />
      <div className='ContainerAPp'>
        <div className='wrapdiv'>
          <div className='firstwrapdiv'>
            <button className='allbutton' onClick={() => handleCategoryChange('')}>All</button>
            {Object.keys(categories).map(category => (
              <div className='custom-dropdown' key={category}>
                <select
                  value={selectedCategory === category ? selectedType : ''}
                  onChange={(e) => {
                    handleCategoryChange(category);
                    handleTypeChange(e);
                  }}
                >
                  <option value="">{category}</option>
                  {categories[category].map(type => (
                    <option key={type.name} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
            ))}
          </div>

          <div className='secondwrapdiv'>
            <div className='WrapApp'>
              <div>
                <p>Home/Mockups/{selectedCategory}</p>
                <h1>{selectedCategory} 3D mockups</h1>
                <p>Boxes, cards, and bags can be found everywhere in daily life. Unique designs can attract more audiences. Select a 3D mockup that suits your needs and complete your design online in minutes. Get started just now!</p>
              </div>
              <div>
                <img className="" src="assets/imgs/merged.png" />
              </div>
            </div>
            <div className="home">
              {filteredModels.map((model) => (
                <div key={model.name} className="model-card">
                  <Link style={{ textDecoration: "none" }} to={`/model/${model.linkname}`}>
                    {/* <div className='model-wrap'> */}
                      <img className='model-image' src={model.image} alt={model.name} />
                    {/* </div> */}
                    <p className="model-heading">{model.name}</p>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function App() {
  return (
    <div className='App'>
      <Router>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Navigate to="/model/Sleevedrawerbox" replace />} />
          <Route path="/model/:modelName" element={<ModelDetail />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
