import React, { useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";
import ThreeDScene from './ThreeDScene';
import { IoColorPalette } from "react-icons/io5";
import { FaBox, FaEdit, FaCartPlus } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaFileImage, FaMagnifyingGlassPlus, FaMagnifyingGlassMinus, FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { MdFlipCameraAndroid, MdKeyboardArrowRight } from "react-icons/md";
import Menu from './menu';
import './tab.css';
import { IoMdArrowRoundBack } from "react-icons/io";

const modelConfigs = {
    Sleevedrawerbox: { childNames: ['Top_Logo', 'Front_Logo', 'Back_Logo', 'Left_Logo', 'Bottom_Logo'], position: [0, 0, 0] },
    Boxwithlid: { childNames: ['Top_Logo', 'Front_Logo', 'Right_Logo', 'Back_Logo', 'Left_Logo', 'Bottom_Logo'], position: [0, 0, 0] },
    Fliptopbox: { childNames: ['Top_Logo', 'Front_Logo', 'Back_Logo', 'Left_Logo', 'Right_Logo', 'Bottom_Logo'], position: [0, 0, 0] },
    Tuckendbox: { childNames: ['Front_Logo', 'Back_Logo', 'Right_Logo', 'Left_Logo', 'Bottom_Logo', 'Inner_Logo', 'Top_Logo'], position: [0, 0, 0] },
    Trianglestandcardmockup: { childNames: ['Front_Logo', 'Back_Logo'], position: [0, 0, 0] },
    Invitationpostcardbusinesscard: { childNames: ['Front_Logo', 'Back_Logo'], position: [0, 0, 0] },
    Bag: { childNames: ['Back_Logo', 'Front_Logo'], position: [0, 0, 0] },
};
function ModelDetail() {
    const { modelName } = useParams();
    const [logos, setLogos] = useState([]);
    const [showCustomMaterial, setShowCustomMaterial] = useState(false);
    const [showCustomSize, setShowCustomSize] = useState(false);

    const [selectedSpot, setSelectedSpot] = useState(modelConfigs[modelName].childNames[0]);
    const [flipLogo, setFlipLogo] = useState(false);
    const [logoScale, setLogoScale] = useState(1);
    const [backgroundImage, setBackgroundImage] = useState('');
    const [activeMenu, setActiveMenu] = useState('edit');
    const [fadeProp, setFadeProp] = useState({
        fade: 'fade-in',
    });

    const handleTabClick = (tab) => {
        // 
        // setActiveMenu(tab);
        setFadeProp({
            fade: 'fade-out',
        });


        setTimeout(() => {
            setActiveMenu(tab);
            setFadeProp({
                fade: 'fade-in',
            });
        }, 200);
    };

    const [Hide, setHide] = useState(false);
    const [activeTab, setActiveTab] = useState('color');
    const [activeTabModel, setActiveTabModel] = useState('box');
    const [gradientType, setGradientType] = useState('linear');

    const [bgColor, setBgColor] = useColor("hex", "#ffffff");
    const [gradientStartColor, setGradientStartColor] = useColor("hex", "#ff0000");
    const [gradientEndColor, setGradientEndColor] = useColor("hex", "#0000ff");

    const boxmodels = [
        { linkname: 'Sleevedrawerbox', name: 'Sleeve drawer box', image: '/assets/imgs/Box1.png' },
        { linkname: 'Boxwithlid', name: 'Box with lid', image: '/assets/imgs/Box2.png' },
        { linkname: 'Fliptopbox', name: 'Flip top box', image: '/assets/imgs/Box3.png' },
        { linkname: 'Tuckendbox', name: 'Tuck end box', image: '/assets/imgs/Box4.png' },
    ];
    const cardmodels = [
        { linkname: 'Trianglestandcardmockup', name: 'Triangle stand card mockup', image: '/assets/imgs/Card1.png' },
        { linkname: 'Invitationpostcardbusinesscard', name: 'Invitation post card business card', image: '/assets/imgs/Card2.png' },
    ];
    const bagmodels = [
        { linkname: 'Bag', name: 'Bag', image: '/assets/imgs/Bag.png' },
    ];
    const [logoPositions, setLogoPositions] = useState([]);
    const [color, setColor] = useState('default');
    const [colorname, setColorName] = useState('Kraft');

    const handleColorChange = (newColor) => {
        setColor(newColor);
        if (newColor === "default") {
            setColorName("Kraft")
        } else {
            setColorName("White cardboard")

        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const newLogo = { url: e.target.result, position: selectedSpot };
                setLogos((prevLogos) => [...prevLogos, newLogo]);
                setLogoPositions((prevPositions) => [...prevPositions, selectedSpot]);
            };
            reader.readAsDataURL(file);
        }
    }, [selectedSpot]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop,
    });

    const handleNextSpot = (index) => {
        setLogoPositions((prevPositions) =>
            prevPositions.map((pos, idx) =>
                idx === index
                    ? modelConfigs[modelName].childNames[(modelConfigs[modelName].childNames.indexOf(pos) + 1) % modelConfigs[modelName].childNames.length]
                    : pos
            )
        );
    };

    const handlePreviousSpot = (index) => {
        setLogoPositions((prevPositions) =>
            prevPositions.map((pos, idx) =>
                idx === index
                    ? modelConfigs[modelName].childNames[(modelConfigs[modelName].childNames.indexOf(pos) - 1 + modelConfigs[modelName].childNames.length) % modelConfigs[modelName].childNames.length]
                    : pos
            )
        );
    };

    const handleSpotChange = (event) => {
        setSelectedSpot(event.target.value);
    };

    const handleFlipLogo = (index) => {
        setLogos((prevLogos) => prevLogos.map((logo, idx) => {
            if (idx === index) {
                return { ...logo, flip: !logo.flip };
            }
            return logo;
        }));
    };

    const handleZoomInLogo = (index) => {
        setLogos((prevLogos) => prevLogos.map((logo, idx) => {
            if (idx === index) {
                return { ...logo, scale: (logo.scale || 1) + 0.1 };
            }
            return logo;
        }));
    };

    const handleZoomOutLogo = (index) => {
        setLogos((prevLogos) => prevLogos.map((logo, idx) => {
            if (idx === index) {
                return { ...logo, scale: (logo.scale || 1) - 0.1 };
            }
            return logo;
        }));
    };

    const handleBackgroundColorChange = () => {
        document.querySelector('.App').style.background = bgColor.hex;
    };

    const handleTypeChange = (e) => {
        setGradientType(e.target.value);
    };
    const handleGradientChange = () => {
        const gradient = `${gradientType}-gradient(${gradientStartColor.hex}, ${gradientEndColor.hex})`;
        document.querySelector('.App').style.background = gradient;
    };

    const handleBackgroundImageChange = (image) => {
        setBackgroundImage(image);
        document.querySelector('.App').style.backgroundImage = `url(${image})`;
    };

    const onBackgroundImageDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                handleBackgroundImageChange(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    }, []);
    const [isVisible, setIsVisible] = useState(true);

    const toggleVisibility = () => {
        setIsVisible(prevState => !prevState);
    };
    const [length, setLength] = useState("120.2");
    const [width, setWidth] = useState("180.3");
    const [height, setHeight] = useState("160");

    const handleApply = () => {
        console.log("Length:", length, "Width:", width, "Height:", height);
        // You can pass these values to the 3D model rendering logic or backend here
    };
    const { getRootProps: getBackgroundRootProps, getInputProps: getBackgroundInputProps } = useDropzone({ onDrop: onBackgroundImageDrop, accept: 'image/*' });

    return (
        <div className="wrapper">
            {/* <Menu /> */}
            <div className="model-detail">
                <div className="side-menu">
                    {showCustomMaterial || showCustomSize ? (
                        <div >
                            <button className='BackButton' onClick={() => { setShowCustomMaterial(false); setShowCustomSize(false); setIsVisible(true); }}><IoMdArrowRoundBack />Back</button>
                        </div>
                    ) :
                        <>
                            <button className='HamBurgButton' onClick={toggleVisibility}><GiHamburgerMenu style={{ fontSize: "20px" }} /></button>
                            <button className={`underlineButton ${activeMenu === 'edit' ? 'active' : ''}`} onClick={() => handleTabClick('edit')} title="Edit"><FaEdit style={{ fontSize: "20px" }} /><h1 style={{ padding: "3px 0 0", margin: "0", fontSize: "10px" }}>Edit</h1></button>
                            <button className={`underlineButton ${activeMenu === 'background' ? 'active' : ''}`} onClick={() => handleTabClick('background')} title="Background"><IoColorPalette style={{ fontSize: "20px" }} /><h1 style={{ padding: "3px 0 0", margin: "0", fontSize: "10px" }}>Colors</h1></button>
                            <button className={`underlineButton ${activeMenu === 'models' ? 'active' : ''}`} onClick={() => handleTabClick('models')} title="Models"><FaBox style={{ fontSize: "20px" }} /><h1 style={{ padding: "3px 0 0", margin: "0", fontSize: "10px" }}>Models</h1></button>
                            <button className={`underlineButton ${activeMenu === 'order' ? 'active' : ''}`} onClick={() => handleTabClick('order')} title="Order Now"><FaCartPlus style={{ fontSize: "20px" }} /><h1 style={{ padding: "3px 0 0", margin: "0", fontSize: "10px" }}>Order</h1></button>
                        </>
                    }
                </div>

                {isVisible ? (
                        <div className={`side-content ${fadeProp.fade}`}>
                            {activeMenu === 'edit' && !showCustomMaterial && !showCustomSize && (
                                <div className="edit-menu">
                                    <div>
                                        <select className="model-select" value={selectedSpot} onChange={handleSpotChange}>
                                            {modelConfigs[modelName].childNames.map((spot) => (
                                                <option key={spot} value={spot}>
                                                    {spot}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <FaFileImage style={{ color: "#66b5d9", fontSize: "20px" }} />
                                        <p style={{ padding: "0", margin: "0", fontSize: "13px", color: "#66b5d9" }}>
                                            Upload your image</p>
                                    </div>

                                    <div className="custom-button" onClick={() => { setShowCustomMaterial(true); setIsVisible(false); }}>
                                        <div>
                                            <p>Custom Material</p>
                                            <h6>{colorname}</h6>
                                        </div>
                                        <MdKeyboardArrowRight />
                                    </div>
                                    {/* <br/> */}
                                    <div className="custom-button" onClick={() => { setShowCustomSize(true); setIsVisible(false); }}>
                                        <div>
                                            <p>Custom Size</p>
                                            <h6>{length} x {width} x {height} mm</h6>
                                        </div>
                                        <MdKeyboardArrowRight />
                                    </div>
                                    {/* </div> */}

                                    {logos.length > 0 && logos.map((logo, index) => (
                                        <div key={index}>
                                            <div>
                                                <button className='underline-flip' onClick={() => handleFlipLogo(index)}>
                                                    <MdFlipCameraAndroid style={{ fontSize: "18px" }} /><br />Flip Logo
                                                </button>
                                                <br />
                                                <button className='underline' onClick={() => handleZoomOutLogo(index)} style={{ padding: "10px", borderRadius: "6px" }}>
                                                    <FaMagnifyingGlassPlus style={{ fontSize: "18px" }} />
                                                </button>
                                                <button className='underline' onClick={() => handleZoomInLogo(index)} style={{ padding: "10px", borderRadius: "6px" }}>
                                                    <FaMagnifyingGlassMinus style={{ fontSize: "18px" }} />
                                                </button>
                                            </div>
                                            <p style={{ fontSize: "14px" }}><b>{index + 1}. {logo.position}</b></p>
                                            <button className='arrow-button' onClick={() => handlePreviousSpot(index)}>
                                                <FaArrowLeft style={{ fontSize: "18px" }} />
                                            </button>
                                            <button className='arrow-button' onClick={() => handleNextSpot(index)}>
                                                <FaArrowRight style={{ fontSize: "18px" }} />
                                            </button>
                                            <div className="BorderUnderline"></div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {activeMenu === 'background' && (
                                <div className="background-menu">
                                    <div className="tab-container">
                                        <div className="tab-menu">

                                            <button className={`tab-button ${activeTab === 'color' ? 'active' : ''}`} onClick={() => setActiveTab('color')}>Color</button>
                                            <button className={`tab-button ${activeTab === 'gradient' ? 'active' : ''}`} onClick={() => setActiveTab('gradient')}>Gradient</button>
                                            <button className={`tab-button ${activeTab === 'image' ? 'active' : ''}`} onClick={() => setActiveTab('image')}>Image</button>
                                            <div className={`slider ${activeTab}`} />
                                        </div>
                                    </div>
                                    {activeTab === 'color' && (
                                        <div className="color-tab">
                                            <ColorPicker hideInput={["rgb", "hsv"]} width={300} height={55} color={bgColor} onChange={setBgColor} hideHSV dark />
                                            <button className='underline' onClick={handleBackgroundColorChange}>Apply Background Color</button>
                                        </div>
                                    )}
                                    {activeTab === 'gradient' && (
                                        <div className="gradient-tab">
                                            <ColorPicker hideInput={["rgb", "hsv"]} width={300} height={55} color={gradientStartColor} onChange={setGradientStartColor} hideHSV dark />
                                            <ColorPicker hideInput={["rgb", "hsv"]} width={300} height={55} color={gradientEndColor} onChange={setGradientEndColor} hideHSV dark />
                                            <select style={{ fontSize: "12px" }} className="model-select" value={gradientType} onChange={handleTypeChange}>
                                                <option value="linear">Linear Gradient</option>
                                                <option value="radial">Radial Gradient</option>
                                            </select>
                                            <button className='underline' onClick={handleGradientChange}>Apply Gradient</button>
                                        </div>
                                    )}
                                    {activeTab === 'image' && (
                                        <div className="image-tab">
                                            <div {...getBackgroundRootProps({ className: 'dropzone' })}>
                                                <input {...getBackgroundInputProps()} />
                                                <FaFileImage style={{ color: "#66b5d9", fontSize: "20px" }} />
                                                <p style={{ padding: "0", margin: "0", fontSize: "13px", color: "#66b5d9" }}>
                                                    Upload background image</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {activeMenu === 'models' && (
                                <div className="background-menu">
                                    <div className="tab-container">
                                        <div className="tab-menu">
                                            <button className={`tab-button ${activeTabModel === 'box' ? 'active' : ''}`} onClick={() => setActiveTabModel('box')}>Box</button>
                                            <button className={`tab-button ${activeTabModel === 'card' ? 'active' : ''}`} onClick={() => setActiveTabModel('card')}>Card</button>
                                            <button className={`tab-button ${activeTabModel === 'bag' ? 'active' : ''}`} onClick={() => setActiveTabModel('bag')}>Bag</button>
                                            <div className={`slider ${activeTabModel}`} />
                                        </div>
                                    </div>
                                    {activeTabModel === 'box' && (
                                        <div className="box-tab">
                                            <h4 style={{ textAlign: "left", padding: "0px 0 10px 8px", margin: "0" }}>Gift Boxes</h4>
                                            <div className='Wrapmodels'>
                                                {boxmodels.map((model) => (
                                                    <div key={model.name} className="model-card-inner">
                                                        <Link style={{ textDecoration: "none" }} to={`/model/${model?.linkname}`}>
                                                            <div className='model-wrap-inner'>
                                                                <img className='model-image-inner' src={model.image} alt={model.name} />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {activeTabModel === 'card' && (
                                        <>
                                            <h4 style={{ textAlign: "left", padding: "0px 0 10px 8px", margin: "0" }}>Cards</h4>
                                            <div className='Wrapmodels'>
                                                {cardmodels.map((model) => (
                                                    <div key={model.name} className="model-card-inner">
                                                        <Link style={{ textDecoration: "none" }} to={`/model/${model?.linkname}`}>
                                                            <div className='model-wrap-inner'>
                                                                <img className='model-image-inner' src={model.image} alt={model.name} />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                    {activeTabModel === 'bag' && (
                                        <>
                                            <h4 style={{ textAlign: "left", padding: "0px 0 10px 8px", margin: "0" }}>Bag</h4>
                                            <div className="bag-tab">
                                                <div className='Wrapmodels'>
                                                    {bagmodels.map((model) => (
                                                        <div key={model.name} className="model-card-inner">
                                                            <Link style={{ textDecoration: "none" }} to={`/model/${model?.linkname}`}>
                                                                <div className='model-wrap-inner'>
                                                                    <img className='model-image-inner' src={model.image} alt={model.name} />
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            {activeMenu === 'order' && (
                                <div className="background-menu">
                                    <h4 style={{ textAlign: "left", padding: "0px 0 10px 8px", margin: "0" }}>Order Now</h4>

                                    <div className='custom-dropdown'>

                                        <select
                                            name="units"
                                            id="units"
                                        // onChange={(e) => {
                                        //     // handleCategoryChange(category);
                                        //     // handleTypeChange(e);
                                        // }}
                                        >

                                            <option value="500">500 Units</option>
                                            <option value="1000">1000 units</option>
                                            <option value="1500">1500 units</option>
                                            <option value="2000">2000 units</option>
                                            <option value="2500">2500 units</option>
                                            <option value="3000">3000 units</option>
                                            <option value="3500">3500 units</option>
                                            <option value="4000">4000 units</option>
                                            <option value="4500">4500 units</option>
                                            <option value="5000">5000 units</option>
                                            {/* {categories[category].map(type => ( */}
                                            {/* <option > */}
                                            {/* {type.name} */}
                                            {/* </option> */}
                                            {/* ))} */}
                                        </select>
                                    </div>
                                    <br />
                                    <button style={{ width: "100%", margin: "20px 0 0" }} className="underline">Check out</button>
                                </div>
                            )}
                        </div>
                ) : null}

                        {showCustomMaterial && (
                            <div className="side-content">
                                <p style={{ textAlign: "left" }}>Custom material</p>
                                <div className='matreiWrap'>
                                    <button className={`materialButtonWhite ${color === '#f2f1f0' ? 'active' : ''}`} onClick={() => { setShowCustomMaterial(false); setShowCustomSize(false); setIsVisible(true); handleColorChange('#f2f1f0') }}>White Cardboard</button>
                                    <button className={`materialButtonKraft ${color === 'default' ? 'active' : ''}`} onClick={() => { setShowCustomMaterial(false); setShowCustomSize(false); setIsVisible(true); handleColorChange('default') }} >Kraft</button>
                                </div>
                            </div>
                        )}

                        {showCustomSize && (
                            <div className="side-content">
                                <p style={{ textAlign: "left", padding: "0 0 10px", margin: "0" }}>Custom Size<span style={{ fontWeight: "bolder", fontSize: "12px" }}> (mm)</span></p>
                                <div className='sizeWrap'>
                                    <div className="input-group">
                                        <label style={{ textAlign: "left", fontSize: "12px" }}>Length</label>
                                        <input
                                            className='inputSizes'
                                            type="number"
                                            value={length}
                                            onChange={(e) => setLength(parseFloat(e.target.value))}
                                            // onChange={(e) => setLength(Number(e.target.value))}
                                            min="0"
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label style={{ textAlign: "left", fontSize: "12px" }}>Width</label>
                                        <input

                                            className='inputSizes'
                                            type="number"
                                            value={width}
                                            onChange={(e) => setWidth(parseFloat(e.target.value))}
                                            // onChange={(e) => setWidth(Number(e.target.value))}
                                            min="0"
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label style={{ textAlign: "left", fontSize: "12px" }}>Height</label>
                                        <input

                                            className='inputSizes'
                                            min="0"
                                            type="number"
                                            value={height}
                                            onChange={(e) => setHeight(parseFloat(e.target.value))}
                                        // onChange={(e) => setHeight(Number(e.target.value))}
                                        />
                                    </div>
                                </div>
                                <button className='applyButton' onClick={() => { setShowCustomMaterial(false); setShowCustomSize(false); setIsVisible(true); handleApply() }}>Apply</button>
                            </div>
                        )}
                        <div className="scene-container">
                            <ThreeDScene
                                key={modelName}
                                modelName={modelName}
                                logos={logos}
                                flipLogo={flipLogo}
                                logoScale={logoScale}
                                color={color}
                                logoPositions={logoPositions}

                            />
                        </div>
                    </div>
        </div >
            );
}

            export default ModelDetail;


