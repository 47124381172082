import React, { useRef, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import { useLogoTextures } from './customHooks'; // Import the custom hook

const modelConfigs = {
  Sleevedrawerbox: { childNames: ['Top_Logo', 'Front_Logo', 'Back_Logo', 'Left_Logo', 'Bottom_Logo'], position: [0, 0, 0] },
  Boxwithlid: { childNames: ['Top_Logo', 'Front_Logo', 'Right_Logo', 'Back_Logo', 'Left_Logo', 'Bottom_Logo'], position: [0, 0, 0] },
  Fliptopbox: { childNames: ['Top_Logo', 'Front_Logo', 'Back_Logo', 'Left_Logo', 'Right_Logo', 'Bottom_Logo'], position: [0, 0, 0] },
  Tuckendbox: { childNames: ['Front_Logo', 'Back_Logo', 'Right_Logo', 'Left_Logo', 'Bottom_Logo', 'Inner_Logo', 'Top_Logo'], position: [0, 0, 0] },
  Trianglestandcardmockup: { childNames: ['Front_Logo', 'Back_Logo'], position: [0, 0, 0] },
  Invitationpostcardbusinesscard: { childNames: ['Front_Logo', 'Back_Logo'], position: [0, 0, 0] },
  Bag: { childNames: ['Back_Logo', 'Front_Logo'], position: [0, 0, 0] },
};

function Model({ modelName, logos, color, logoPositions, originalColor, setOriginalColor }) {
  const { scene } = useGLTF(`/assets/models/${modelName}.glb`);
  const logoTextures = useLogoTextures(logos); // Use the custom hook

  const originalTextures = useRef({});
  const ref = useRef();

  useEffect(() => {
    scene.traverse((child) => {
      if (child.isMesh && modelConfigs[modelName].childNames.includes(child.name)) {
        if (!originalTextures.current[child.name]) {
          originalTextures.current[child.name] = child.material.map;
        }
        const logoIndex = logos.findIndex((logo, index) => logoPositions[index] === child.name);
        if (logoIndex !== -1 && logoTextures[logoIndex]) {
          const logoTexture = logoTextures[logoIndex];
          logoTexture.flipY = logos[logoIndex].flip || false;
          logoTexture.needsUpdate = true;
          logoTexture.center.set(0.5, 0.5);
          child.material.map = logoTexture;
          child.material.map.repeat.set(logos[logoIndex].scale || 1, logos[logoIndex].scale || 1);
        } else {
          child.material.map = originalTextures.current[child.name];
        }
        child.material.needsUpdate = true;
      }
    });
  }, [logos, logoPositions, scene, modelName, logoTextures]);

  useEffect(() => {
    scene.traverse((child) => {
      if (child.isMesh && !modelConfigs[modelName].childNames.includes(child.name)) {
        if (!originalColor.current) {
          originalColor.current = child.material.color.clone();
        }
        child.material.color.set(color === 'default' ? originalColor.current : color);
      }
    });
  }, [color, scene, modelName, originalColor]);

  return <primitive object={scene} ref={ref} position={modelConfigs[modelName]?.position} />;
}

function ThreeDScene({ flipLogo, logoScale, color, logos, modelName, logoPositions }) {
  const originalColor = useRef(null);

  return (
    <Canvas style={{ height: '100vh' }} camera={{ position: [0, 2, 4] }}>
      <ambientLight intensity={0.8} />
      <directionalLight position={[5, 10, 7.5]} intensity={1.9} />
      <directionalLight position={[-5, -10, -7.5]} intensity={0.9} />
      <directionalLight position={[-5, 10, -7.5]} intensity={0.9} />
      <spotLight position={[15, 20, 5]} angle={0.3} intensity={0.5} castShadow />
      <Model
        modelName={modelName}
        logos={logos}
        flipLogo={flipLogo}
        logoScale={logoScale}
        color={color}
        logoPositions={logoPositions}
        originalColor={originalColor}
      />
      <OrbitControls />
    </Canvas>
  );
}

export default ThreeDScene;
